import {
  CollapseContainer,
  CollapsePanelContainer,
  CollapsePanelHeader,
} from "@hellodarwin/core/lib/components/common/Collapse/styles";
import Button from "@hellodarwin/core/lib/components/common/button";
import { ArrowContainer } from "@hellodarwin/core/lib/components/common/button/arrow/styles";
import Div from "@hellodarwin/core/lib/components/common/div";
import { ParagraphContainer } from "@hellodarwin/core/lib/components/common/typography/styles";
import styled, { css } from "@hellodarwin/core/lib/plugins/styled";
import Drawer from "antd/es/drawer";
import { Link } from "gatsby";
import { MenuItemEntity } from "../../../../features/entities/layout-entities";
import theme from "../../../../utils/styles/theme";

const menuItemStyle = css<{ $isFeatured?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  transition: background 400ms ease;
  border-radius: 4px;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  text-wrap: nowrap;

  ${ArrowContainer} {
    width: 15px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    &:hover,
    &.active {
      background: ${(props) => props.theme.colors.beige_2};
      color: ${(props) => props.theme.colors.grey_1};

      ${ArrowContainer} {
        width: 20px;
      }
    }
  }

  ${({ $isFeatured, theme }) =>
    $isFeatured
      ? `
               color:${theme.colors.purple_1};
               text-decoration-line: underline;      
               text-underline-offset:4px;    
               &:hover{
                color:${theme.colors.purple_2};
               text-decoration-line: underline;      
               text-underline-offset:4px;  
               }
                `
      : undefined}

  &::before,
  &::after {
    display: none;
  }
`;

export const LargeMenuItemContainer = styled(Div)<{ $isFeatured?: boolean }>`
  ${menuItemStyle}
  padding: 30px 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0;
  }
`;

export const SmallMenuItemContainer = styled(Div)<{ $isFeatured?: boolean }>`
  ${menuItemStyle}
  padding: 7px 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0;
  }
`;

export const MediumMenuItemContainer = styled(Div)<{ $isFeatured?: boolean }>`
  ${menuItemStyle}
  letter-spacing: 0.36px;
`;

export const ArticleMenuItemContainer = styled(Div)<{ $isFeatured?: boolean }>`
  ${menuItemStyle}
  width:333px;
  max-width: 100%;
  padding: 0;
  gap: 0;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  align-items: stretch;
  overflow: hidden;
`;

export const MenuDrawer = styled(Drawer)<{
  $direction?: MenuItemEntity["direction"];
}>`
  margin-top: ${({ theme }) => theme.appearance.headerHeight};
  min-height: ${({ $direction }) => ($direction === "column" ? 288 : 144)}px;
  height: calc(100vh - ${({ theme }) => theme.appearance.headerHeight});
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
`;

const ChildNavItemLinkSmall = css`
  ${SmallMenuItemContainer} {
    margin: 0 16px;
  }
  &:first-of-type {
    ${SmallMenuItemContainer} {
      margin-top: 25px;
    }
  }
  &:last-of-type {
    ${SmallMenuItemContainer} {
      margin: 16px 0;
    }
  }
`;
const ChildNavItemLinkLarge = css`
  position: relative;
  ${LargeMenuItemContainer} {
    margin: 8px;
  }
  &:after {
    content: "";
    display: block;
    width: 100vw;
    position: absolute;
    right: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  }
  &:last-of-type {
    border: none;
    &:after {
      display: none;
    }
  }
`;

const ChildNavItemLinkMedium = css`
  ${MediumMenuItemContainer} {
    margin: 11px 16px;
  }

  &:first-of-type {
    ${MediumMenuItemContainer} {
      margin-top: 22px;
    }
  }
`;
const ChildNavItemLinkArticle = css`
  ${ArticleMenuItemContainer} {
    margin: 5px 24px;
  }

  &:first-of-type {
    ${ArticleMenuItemContainer} {
      margin-top: 32px;
    }
  }
  &:last-of-type {
    ${ArticleMenuItemContainer} {
      margin-left: 26px;
      margin-bottom: 16px;
    }
  }
`;
export const ChildNavItemA = styled.a<{
  $type: "small" | "medium" | "large" | "article";
  $isFeatured?: boolean;
}>`
  ${(props) =>
    props.$type === "article"
      ? ChildNavItemLinkArticle
      : props.$type === "small"
        ? ChildNavItemLinkSmall
        : props.$type === "large"
          ? ChildNavItemLinkLarge
          : ChildNavItemLinkMedium}
`;
export const ChildNavItemLink = styled(Link)<{
  $type: "small" | "medium" | "large" | "article";
  $isFeatured?: boolean;
}>`
  ${(props) =>
    props.$type === "article"
      ? ChildNavItemLinkArticle
      : props.$type === "small"
        ? ChildNavItemLinkSmall
        : props.$type === "large"
          ? ChildNavItemLinkLarge
          : ChildNavItemLinkMedium}

  &:hover {
    ${ArrowContainer} {
      width: 30px;
    }
  }
`;

const MenuButtonTypographyHover = css`
  text-underline-offset: 6px;
  text-decoration: underline;
  cursor: pointer;
`;

export const MenuButtonTypography = styled(Button)<{ $isOpen?: boolean }>`
  &:before,
  &:after {
    display: none;
  }
  display: flex;
  gap: 8px;
  font-family: Figtree;
  font-weight: ${theme.fontWeights.medium};
  align-items: center;
  min-width: auto;
  padding: 0;
  ${({ $isOpen }) => ($isOpen ? MenuButtonTypographyHover : "")}
  &:hover {
    ${MenuButtonTypographyHover}
  }
  svg {
    path {
      strokewidth: 2.5;
    }
  }
`;

export const MenuItemsContainer = styled(Div)`
  .menu-section {
    border-right: 1px solid ${(props) => props.theme.colors.primary};
    &:last-of-type {
      border: none;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    }
  }
`;
export const ChildListItemsContainer = styled(Div)`
  width: 100%;
  border-right: 1px solid ${(props) => props.theme.colors.primary};

  &:last-of-type {
    border-right: none;
  }
  &.services,
  &.articles,
  &.podcasts {
    ${ChildNavItemLink}:last-of-type {
      ${SmallMenuItemContainer} {
        color: ${(props) => props.theme.colors.purple_1};
        text-decoration: underline;
        font-weight: bold;
        padding: 0;
        margin: 16px 24px;

        &:hover {
          background-color: transparent;
          text-decoration: none;
          color: ${(props) => props.theme.colors.purple_3};
        }
        @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
          margin: 5px 0;
        }
      }
    }
  }

  &.services {
    ${ChildNavItemLink}:last-of-type {
      ${SmallMenuItemContainer} {
        margin: 16px 38px;
        @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
          margin: 5px 0;
        }
      }
    }
  }
  &.no-parent {
    border-right: 1px solid ${(props) => props.theme.colors.primary};
    margin-right: 24px;
  }
`;
export const ItemsListContainer = styled(Div)`
  &.row {
    justify-content: stretch;
    & > * {
      display: flex;
    }
    ${ChildNavItemLink} {
      &:after {
        width: 1px;
        top: 0;
        height: 100%;
        border-bottom: none;
        border-left: 1px solid ${(props) => props.theme.colors.primary};
      }
    }
  }

  ${ChildListItemsContainer} {
    border-left: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

export const LinkContainer = styled.div`
  margin: 24px 41px;
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 24px 0;
  }
`;
export const RecentSection = styled(Div)`
  margin: 5px 24px;
  margin-top: 32px;
  ${ChildNavItemLink} {
    margin: 0;

    &,
    &:first-of-type,
    &:last-of-type {
      ${ArticleMenuItemContainer} {
        margin: 0;
      }
    }
  }
`;

export const MobileDrawerContentStyling = css`
  padding: 24px;
  ${ItemsListContainer} {
    flex-direction: column;
    ${ChildListItemsContainer} {
      border: none;
    }
    ${ChildNavItemLink} {
      &:after {
        display: none;
      }
    }
  }

  ${ChildNavItemLink} {
    margin-bottom: 22px;

    ${LargeMenuItemContainer},${SmallMenuItemContainer},${MediumMenuItemContainer} {
      margin: 0;
      padding: 0 !important;
      width: 100% !important;
      ${ParagraphContainer} {
        padding: 0 !important;
      }
    }

    ${ArticleMenuItemContainer} {
      margin: 0;
    }
  }
  ${RecentSection} {
    margin: 0;
    margin-bottom: 22px;

    ${ChildNavItemLink} {
      margin-bottom: 0;
    }
  }
`;

export const MobileDrawer = styled(Drawer)`
  margin-top: ${({ theme }) => theme.appearance.headerHeight};
  height: calc(
    100dvh - ${({ theme }) => theme.appearance.headerHeight}
  ) !important;

  .ant-drawer-header-title {
    align-items: center;
    justify-content: space-between;
    .ant-drawer-close {
    }
    .ant-drawer-title {
      width: fit-content;
    }
  }

  .ant-drawer-header {
    margin: 0 24px;
    padding: 24px 0;
  }
  .ant-drawer-body {
    ${MobileDrawerContentStyling}
  }
  ${CollapseContainer} {
    & > ${CollapsePanelContainer} {
      border: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};
      & > ${CollapsePanelHeader} {
        padding: 16px 24px;
        margin: 0;
      }
      .ReactCollapse--content {
        border: none;
        background: transparent;
        padding: 24px 48px;
        .ant-collapse-content-box,
        .menu-section {
          padding: 0;
          width: 100% !important;
        }
        ${MobileDrawerContentStyling}
      }
    }
  }
`;

export const HeaderLogoContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
  }
`;

