import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import { LOCALE_CACHE_KEY } from "@hellodarwin/core/lib/features/providers/locale-provider";
import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-use";
import { PageLinks, QueryNodes } from "../features/entities/directory-entities";
import { PageContext } from "../features/entities/pages-entities";
import { SUPPORTED_LANGUAGES } from "../slices/Header/components/LanguageSwitcher";
import { useRoot } from "./RootProvider";

type PageProviderProps = {
  pageContext: PageContext;
  data: Record<string, unknown>;
} & PropsWithChildren;

const PageProvider = ({ children, pageContext, data }: PageProviderProps) => {
  const {
    changeLanguage,
    selectedLocale,
    redirectToPageContext,
    setLocalePageContext,
    addCrumb,
  } = useRoot();
  const { pathname } = useLocation();
  const [isLoading, setLoading] = useState(false);

  const addComponentTypesToLocations = () => {
    if (pathname && data) {
      const componentsTypes = Object.keys(data);
      addCrumb({
        pathname,
        tags: pageContext.tags,
        componentsTypes,
        pageName: pageContext.pageName,
      });
    }
  };

  useEffect(() => {
    addComponentTypesToLocations();
  }, []);
  useEffect(() => {
    addComponentTypesToLocations();
  }, [data]);

  const prepareLocale = () => {
    const matchingLinks: PageLinks[] =
      (data?.matchingPageLinks as QueryNodes<PageLinks>)?.nodes || undefined;

    const otherLang = SUPPORTED_LANGUAGES.find(
      (lang) => selectedLocale !== lang
    );

    if (matchingLinks) {
      const otherPageLink = matchingLinks.find(
        (l) => l.node_locale === otherLang
      );

      if (!!otherPageLink) {
        setLocalePageContext(matchingLinks);
      } else {
        setLocalePageContext([]);
      }
    }
    setLoading(false);
  };

  const handleRedirect = () => {
    setLoading(true);
    const storedLanguage = localStorage.getItem(LOCALE_CACHE_KEY) as Language;
    if (!storedLanguage) {
      const browserLanguage = navigator.language.split("-")[0] as Language;

      if (browserLanguage.includes("fr")) {
        const matchingLinks: PageLinks[] =
          (data?.matchingPageLinks as QueryNodes<PageLinks>)?.nodes ||
          undefined;

        const context = matchingLinks?.find(
          ({ node_locale }) => node_locale === browserLanguage
        );
        if (context) {
          redirectToPageContext(context);
        }
        changeLanguage(browserLanguage);
      } else if (pageContext?.locale !== selectedLocale) {
        setTimeout(() => {
          changeLanguage(pageContext?.locale);
        }, 0);
      }
    } else if (pageContext?.locale !== selectedLocale) {
      setTimeout(() => {
        changeLanguage(pageContext?.locale);
      }, 0);
    }
    prepareLocale();
  };

  useEffect(() => {
    if (isLoading) return;
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocale, pathname, pageContext]);

  const [opacity, setOpacity] = useState(0);
  useEffect(() => setOpacity(1), []);

  return (
    <div style={{ opacity, transition: "opacity 50ms linear" }}>{children}</div>
  );
};

export default PageProvider;

