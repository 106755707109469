import { Language } from "@hellodarwin/core/lib/features/entities/translations_ns";
import useLocale from "@hellodarwin/core/lib/features/providers/locale-provider";
import { navigate } from "gatsby";
import React, {
  PropsWithChildren,
  ReactNode,
  useContext,
  useState,
} from "react";
import { PageLinks } from "../features/entities/directory-entities";

type PageContext = PageLinks[];

interface Breadcrumb {
  pathname: string;
  pageName: string;
  tags?: string[];
  componentsTypes?: string[];
}
export interface RootContextType {
  changeLanguage: (locale: Language) => void;
  selectedLocale: Language;
  handleLanguageSwitcher: (newLang: Language) => void;
  redirectToPageContext: (context: PageLinks) => void;
  setLocalePageContext: (value: PageContext) => void;
  hasData: boolean;
  setHasData: (hasData: boolean) => void;
  breadcrumbs: Breadcrumb[];
  addCrumb: (newCrumb: Breadcrumb) => void;
  setHeaderActions: (headerActions: ReactNode) => void;
  headerActions: ReactNode;
}

export const RootContext = React.createContext<RootContextType>({
  changeLanguage: (locale) => {},
  selectedLocale: "en",
  handleLanguageSwitcher: (newLang) => {},
  redirectToPageContext(context) {},
  setLocalePageContext: (value) => {},
  hasData: false,
  setHasData: (hasData) => {},
  breadcrumbs: [],
  addCrumb: (newCrumb) => {},
  setHeaderActions(headerActions) {},
  headerActions: undefined,
});

export const RootProvider = ({ children }: PropsWithChildren) => {
  const { selectedLocale, changeLanguage } = useLocale();
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [headerActions, setHeaderActions] = useState<ReactNode>(undefined);

  const [localePageContext, setLocalePageContext] = React.useState<PageContext>(
    []
  );

  const [hasData, setHasData] = React.useState(false);

  const handleLanguageSwitcher = (newLang: Language) => {
    changeLanguage(newLang);
    const context = localePageContext?.find(
      ({ node_locale }) => node_locale === newLang
    );
    redirectToPageContext(context);
  };

  const redirectToPageContext = (context?: PageLinks) => {
    if (context) {
      if (context.fullHandle) {
        const link = context as PageLinks;
        navigate(`/${link.fullHandle}`);
      }
    }
  };

  const addCrumb = (crumb: Breadcrumb) => {
    if (breadcrumbs[breadcrumbs?.length - 1]?.pathname !== crumb.pathname) {
      setBreadcrumbs([...breadcrumbs, crumb]);
    }
  };
  return (
    <RootContext.Provider
      value={{
        changeLanguage,
        selectedLocale,
        handleLanguageSwitcher,
        redirectToPageContext,
        setLocalePageContext,
        hasData,
        setHasData,
        breadcrumbs,
        addCrumb,
        headerActions,
        setHeaderActions,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

export const useRoot = () => {
  const { ...rootVariables } = useContext(RootContext);
  return {
    ...rootVariables,
  };
};

export default RootContext;

