import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useSessionStorage } from "react-use";

interface UrlParamsEntity {
  campaignid: string | null;
  adgroupid: string | null;
  kw: string | null;
  gclid: string | null;
  source_url: string | null;
  lp_url: string | null;
  fbclid: string | null;
  referral_code: string | null;
}
const InitialUrlParamsEntity: UrlParamsEntity = {
  campaignid: null,
  adgroupid: null,
  kw: null,
  gclid: null,
  source_url: null,
  lp_url: null,
  fbclid: null,
  referral_code: null,
};
export interface URLParamContextType {
  urlParams: UrlParamsEntity;
  setUrlParams: (urlParams: UrlParamsEntity) => void;
}

export const URLParamContext = React.createContext<URLParamContextType>({
  urlParams: InitialUrlParamsEntity,
  setUrlParams: (urlParams) => {},
});

export const URLParamProvider = ({
  children,
  location,
}: { location: Location } & PropsWithChildren) => {
  const [urlParams, setUrlParams] = useSessionStorage<UrlParamsEntity>(
    "campaign-url-params",
    undefined
  );
  const [landingPageUrl, setLandingPageUrl] = useSessionStorage<string>(
    "lp_url",
    undefined
  );

  if (landingPageUrl === undefined) {
    setLandingPageUrl(location.href);
  }

  useEffect(() => {
    let url = new URL(location.href);
    let campaignid = urlParams?.campaignid || null;
    let adgroupid = urlParams?.adgroupid || null;
    let kw = urlParams?.kw || null;
    let gclid = urlParams?.gclid || null;
    let fbclid = urlParams?.fbclid || null;
    let referral_code = urlParams?.referral_code || null;
    let lp_url = landingPageUrl || null;

    if (location?.search) {
      campaignid = !!campaignid
        ? campaignid
        : url?.searchParams?.get("campaignid");
      adgroupid = !!adgroupid ? adgroupid : url?.searchParams?.get("adgroupid");
      kw = !!kw ? kw : url?.searchParams?.get("kw");
      gclid = !!gclid ? gclid : url?.searchParams?.get("gclid");
      fbclid = !!fbclid ? fbclid : url?.searchParams?.get("fbclid");
      referral_code = !!referral_code
        ? referral_code
        : url?.searchParams?.get("referral_code");
      lp_url = landingPageUrl;
    }

    setUrlParams({
      campaignid,
      adgroupid,
      kw,
      gclid,
      source_url: `${location.origin}${location.pathname}`,
      lp_url,
      fbclid,
      referral_code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <URLParamContext.Provider
      value={{
        urlParams: urlParams!,
        setUrlParams,
      }}
    >
      {children}
    </URLParamContext.Provider>
  );
};

export const useURLParam = () => {
  const { urlParams, setUrlParams } = useContext(URLParamContext);
  return {
    urlParams,
    setUrlParams,
  };
};

export default URLParamContext;

