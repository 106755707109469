import { useTranslations } from "@hellodarwin/core/lib/features/providers/translations-provider";
import { Slice } from "gatsby";
import { PropsWithChildren, useEffect } from "react";
import { URLParamProvider } from "../../context/URLParamProvider";
import { PageContext } from "../../features/entities/pages-entities";

export type DefaultLayoutProps = {
  element: PropsWithChildren["children"];
  props: {
    pageContext: PageContext;
    location: Location;
    data: Record<string, unknown>;
  };
};

const DefaultLayout = ({ element, props }: DefaultLayoutProps) => {
  const { prepareTranslations } = useTranslations();

  const handleTranslations = (data: any) => {
    if (!data.allTranslations) return;

    prepareTranslations((data.allTranslations as any)?.nodes);
  };

  useEffect(() => {
    handleTranslations(props.data);
  }, [props.data]);

  return (
    <div style={{ position: "relative" }}>
      <Slice alias="header" allowEmpty />

      <URLParamProvider location={props.location}>
        <main
          id="main"
          className={
            props.pageContext.designType === "Email"
              ? "is-email"
              : props.pageContext.designType === "Simple"
                ? "is-simple"
                : ""
          }
        >
          {element}
        </main>
      </URLParamProvider>
      <Slice alias="footer" allowEmpty />
    </div>
  );
};

export default DefaultLayout;

